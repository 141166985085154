html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

ol,
ul {
  padding: 0 2rem !important;
}
